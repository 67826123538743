import React, { useState, useEffect } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithPopup,
  onAuthStateChanged,
  OAuthProvider,
  GoogleAuthProvider,
  linkWithCredential,
  EmailAuthProvider,
  linkWithPopup,
  sendEmailVerification,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import "./SignIn.css"; // Import a CSS file for styling
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { nanoid } from "nanoid";
import { Snackbar, Alert, Button } from "@mui/material";
import { getAnalytics, logEvent } from "firebase/analytics";

const SignUp = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState(""); // State for confirming email
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [user, setUser] = useState(null); // State to hold user info
  const [openSnackbar, setOpenSnackbar] = useState(false); // Alert visibility

  const auth = getAuth();
  const analytics = getAnalytics();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user); // Update the state with the signed-in user's info
        setError(""); // Clear any error messages
      } else {
        setUser(null); // Reset user info if not logged in
      }
    });

    return () => unsubscribe(); // Clean up the subscription
  }, [auth]);

  // Analytics
  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_title: "Sign Up Page",
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }, []);

  const createUserInFirestore = async (user) => {
    const firestore = getFirestore();
    const userDocRef = doc(firestore, "Users/UsersList/DataBase", user.uid);

    // Add the user to Firestore
    await setDoc(
      userDocRef,
      {
        uid: user.uid,
        email: user.email,
        isAnonymous: false, // Make sure isAnonymous is set to false after linking or signing up
        createdAt: new Date(),
        user_code: nanoid(6), // Generate a random 6-character user code
      },
      { merge: true }
    );
  };

  const linkAnonymousAccount = async (provider) => {
    try {
      // If the user is signing up with email and password
      if (provider === "email") {
        const credential = EmailAuthProvider.credential(email, password);
        const linkedUser = await linkWithCredential(
          auth.currentUser,
          credential
        );
        return linkedUser.user;
      }
      // If the user is using a third-party provider like Google or Apple
      else {
        const linkedUser = await linkWithPopup(auth.currentUser, provider);
        return linkedUser.user;
      }
    } catch (error) {
      throw new Error("Error linking anonymous account: " + error.message);
    }
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    alert("start");

    // Check if emails match
    if (email !== confirmEmail) {
      setError("Emails do not match.");
      return;
    }

    if (auth.currentUser && auth.currentUser.isAnonymous) {
      try {
        const linkedUser = await linkAnonymousAccount("email"); // Link Email account
        setUser(linkedUser);
        setError("");
        alert("created user in firestore");
        await createUserInFirestore(linkedUser); // Update Firestore and set isAnonymous to false

        // Send email verification
        await sendEmailVerification(linkedUser);
        setOpenSnackbar(true);

        navigate(`/`);
      } catch (error) {
        setError(error.message);
      }
      logEvent(analytics, "sign_up", {
        method: "anonymous",
      });
    } else {
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          const user = userCredential.user;
          setUser(user);
          setError("");

          await createUserInFirestore(user);

          // Send email verification
          await sendEmailVerification(user);
          setOpenSnackbar(true);

          navigate(`/`);
        })
        .catch((error) => {
          setError(error.message);
        });
      logEvent(analytics, "sign_up", {
        method: "email",
      });
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    if (auth.currentUser && auth.currentUser.isAnonymous) {
      try {
        const linkedUser = await linkAnonymousAccount(provider); // Link Google account
        setUser(linkedUser);
        await createUserInFirestore(linkedUser);
        navigate(`/`);
      } catch (error) {
        setError(error.message);
      }
    } else {
      signInWithPopup(auth, provider)
        .then(async (result) => {
          setUser(result.user);
          await createUserInFirestore(result.user);
          navigate(`/`);
        })
        .catch((error) => {
          setError(error.message);
        });
    }
    logEvent(analytics, "sign_up", {
      method: "google",
    });
  };

  const handleAppleSignIn = async () => {
    const provider = new OAuthProvider("apple.com");
    if (auth.currentUser && auth.currentUser.isAnonymous) {
      try {
        const linkedUser = await linkAnonymousAccount(provider); // Link Apple account
        setUser(linkedUser);
        await createUserInFirestore(linkedUser);
        navigate(`/`);
      } catch (error) {
        setError(error.message);
      }
    } else {
      signInWithPopup(auth, provider)
        .then(async (result) => {
          setUser(result.user);
          await createUserInFirestore(result.user);
          navigate(`/`);
        })
        .catch((error) => {
          setError(error.message);
        });
    }
    logEvent(analytics, "sign_up", {
      method: "apple",
    });
  };

  return (
    <div className="signin-container">
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}>
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity="success"
          action={
            <Button color="inherit" size="small" onClick={() => navigate(`/`)}>
              Shop Now
            </Button>
          }>
          You’re signed up! Please check your email to verify your account.
        </Alert>
      </Snackbar>

      <div onClick={() => navigate(`/`)} style={{ cursor: "pointer" }}>
        <img
          src="/images/sooperstock_logo.svg"
          alt="Sooperstock"
          style={{ height: "47px", marginBottom: 10 }}
        />
      </div>
      <p
        style={{
          marginBottom: 20,
          fontSize: 12,
          textTransform: "uppercase",
          marginBottom: 50,
        }}>
        The plant wellness superstore
      </p>

      <form className="signin-form" onSubmit={handleSignUp}>
        <label>Sign up with email</label>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Confirm Email"
          value={confirmEmail}
          onChange={(e) => setConfirmEmail(e.target.value)}
          required
          onPaste={(e) => e.preventDefault()} // Disable paste to avoid copy-pasting
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit" className="btn btn-primary">
          Continue with email
        </button>
      </form>

      <button className="btn btn-secondary" onClick={handleGoogleSignIn}>
        <img
          src="images/google_icon.png"
          style={{
            height: "12px",
            top: "0px",
            marginRight: "3px",
            position: "relative",
          }}
        />{" "}
        Continue With Google
      </button>

      <button className="btn btn-primary btn-apple" onClick={handleAppleSignIn}>
        <span role="img" aria-label="apple">
          
        </span>{" "}
        Sign in with Apple
      </button>

      {error && <p className="error">{error}</p>}

      <p className="footer-text">
        Already have an account? <a href="/signin">Log In</a>
      </p>

      <p className="footer-text">
        By creating an account, you agree to Sooperstock's{" "}
        <a href="/terms">terms of service</a>.
      </p>
      <p
        className="footer-text"
        onClick={() => navigate(`/`)}
        style={{ cursor: "pointer" }}>
        Go to <u>HomePage</u>.
      </p>
    </div>
  );
};

export default SignUp;
