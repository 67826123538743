import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC5JFSlefBl7pnJysUl4iEmRVp3S5KM8X0",
  authDomain: "sooperstock.com",
  projectId: "sooperstock-app",
  storageBucket: "sooperstock-app.appspot.com",
  messagingSenderId: "277475901921",
  appId: "1:277475901921:web:301af934e1046d2cfe12a0",
  measurementId: "G-6E04Z8KFTD",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = process.env.REACT_APP_ENV === "production" ? getAnalytics(app) : null;

export { auth, db, app, analytics };
