import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import "./SignIn.css"; // Reuse the same CSS for styling

const ForgottenPass = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleResetPassword = async (event) => {
    event.preventDefault();
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("If the email exists, you will receive a password reset link.");
      setError("");
    } catch (error) {
      setError("Failed to send password reset email. Please try again.");
      setMessage("");
    }
  };

  return (
    <div className="signin-container">
      <a href="/" style={{ cursor: "pointer" }}>
        <img src="/images/sooperstock_logo.svg" alt="Sooperstock" style={{ height: "47px", marginBottom: 10 }} />
      </a>
      <p
        style={{
          marginBottom: 20,
          fontSize: 12,
          textTransform: "uppercase",
          marginBottom: 50,
        }}
      >
        The plant wellness superstore
      </p>

      <form className="signin-form" onSubmit={handleResetPassword}>
        <label>Reset Password</label>
        <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
        <button type="submit" className="btn btn-primary">
          Send Reset Email
        </button>
      </form>

      {message && <p className="message">{message}</p>}
      {error && <p className="error">{error}</p>}

      <p className="footer-text">
        Remembered your password? <a href="/signin">Sign In</a>
      </p>
      <p className="footer-text" onClick={() => navigate(`/`)} style={{ cursor: "pointer" }}>
        Go to <u>HomePage</u>.
      </p>
    </div>
  );
};

export default ForgottenPass;
