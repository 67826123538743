import React, { useEffect, useRef, useState, useContext } from "react";
import config from "../config";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useFirestore } from "../firebase/FirestoreContext";
import { doc, updateDoc } from "firebase/firestore";
import { EnvContext } from "../context/EnvContext";

const SquarePaymentForm = ({ orderAmount, onSuccess, onFailure, totalAmount, address }) => {
  const environment = useContext(EnvContext);
  const { analytics } = useContext(EnvContext);
  const location = useLocation();
  const navigate = useNavigate();
  const db = useFirestore();

  const { cartID, items, subtotal, shippingCostsubtotal, cart_sooperstock_ID } = location.state || {};

  const paymentFormRef = useRef(null);
  const initializedRef = useRef(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [cardInstance, setCardInstance] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [addressFilled, setAddressFilled] = useState(address);
  const [paymentProcessing, setPaymentProcessing] = useState(false);

  // Determine the correct collection path based on the environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  useEffect(() => {
    const loadSquareSDK = () => {
      // Check if the Square SDK is already loaded
      if (window.Square) {
        initializePayment();
      } else {
        // Dynamically create a script tag to load the Square SDK
        const script = document.createElement("script");
        script.src = "https://sandbox.web.squarecdn.com/v1/square.js"; // Use production URL in production
        script.type = "text/javascript";
        script.async = true;
        script.onload = () => {
          initializePayment();
        };
        script.onerror = () => {
          console.error("Square SDK failed to load.");
        };
        document.head.appendChild(script);
      }
    };

    const initializePayment = async () => {
      if (!window.Square) {
        console.error("Square SDK not loaded");
        return;
      }

      if (initializedRef.current) return;
      initializedRef.current = true;

      try {
        // Initialize the Square payments object with your Application ID and Location ID
        const payments = window.Square.payments("sandbox-sq0idb-SoBz7295GsoqUiKfc98eJA", "LDE9GXWT6GHE0"); // Replace with your IDs

        const card = await payments.card();
        await card.attach(paymentFormRef.current);
        setCardInstance(card);
        console.log("Card instance initialized");

        // Add event listener for card input changes
        card.addEventListener("change", (event) => {
          const { status } = event.detail;
          if (status === "VALID") {
            setCardComplete(true);
          } else {
            setCardComplete(false);
          }
        });
      } catch (error) {
        console.error("Error initializing Square payments:", error);
      }
    };

    loadSquareSDK();

    // Cleanup function
    return () => {
      if (cardInstance) {
        cardInstance.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAddressFilled(address);
  }, [address]);

  const handlePaymentClick = async (event) => {
    event.preventDefault();
    setPaymentStatus("Processing payment...");
    setPaymentProcessing(true);

    // Add address to cart document
    const docRef = doc(db, `${collectionPath}Carts/CartsList/DataBase`, cartID);
    await updateDoc(docRef, {
      shipmentAddress: address,
    });

    if (!cardInstance) {
      console.error("Card instance not available");
      setPaymentStatus("Card instance not available");
      setPaymentProcessing(false);
      return;
    }

    try {
      const result = await cardInstance.tokenize();
      if (result.status === "OK") {
        await handlePaymentSuccess(result.token, totalAmount);
        setPaymentProcessing(false);
      } else {
        console.error("Tokenization failed:", result.errors);
        setPaymentStatus("Payment failed: " + result.errors[0].message);
        handlePaymentFailure(result.errors);
        setPaymentProcessing(false);
      }
    } catch (error) {
      console.error("Error during payment processing:", error);
      setPaymentStatus("Failed to process payment: " + error.message);
      setPaymentProcessing(false);
    }
  };

  const handlePaymentSuccess = async (token, amount) => {
    try {
      const response = await axios.post(`${config.apiConfig.API_ENDPOINT_URL}/payment/squarepay`, {
        sourceId: token,
        amount: Math.round(amount * 100), // Amount in cents
        cart_id: cartID,
      });

      if (response.status === 200) {
        console.log("Payment successful!");
        setPaymentStatus("Payment Successful!");
        onSuccess();
        // Navigate to payment success page
        navigate("/payment-success", {
          state: {
            items: items,
            cart_sooperstock_ID: cart_sooperstock_ID,
          },
        });
      } else {
        console.error("Payment failed:", response);
        setPaymentStatus("Payment failed: " + response.data.message);
        onFailure();
      }
    } catch (error) {
      console.error("Error during payment processing:", error.response ? error.response.data : error.message);
      setPaymentStatus("Payment failed");
      onFailure();
    }
  };

  const handlePaymentFailure = (errors) => {
    setPaymentStatus("Payment failed");
    console.error("Payment failed:", errors);
    onFailure();
  };

  return (
    <div style={{ padding: "20px 0px" }}>
      <div id="card-container" ref={paymentFormRef}></div>

      <div style={styles.summarySection}>
        <div style={styles.summaryRow}>
          <span>
            Items:
            {
              // list of items in cart
              items &&
                items.length > 0 &&
                items.map((item) => (
                  <div key={item.product_id} style={{ padding: "1px 0", opacity: 0.7 }}>
                    <span style={{ fontSize: "12px" }}>
                      - {item.product_name} x {item.quantity}
                    </span>
                  </div>
                ))
            }
          </span>
          <span>${subtotal ? subtotal.toFixed(2) : "0.00"}</span>
        </div>
        <div style={styles.summaryRow}>
          <span>Shipping:</span>
          <span style={styles.greenText}>${shippingCostsubtotal ? shippingCostsubtotal.toFixed(2) : "0.00"}</span>
        </div>
        <div style={styles.subtotalRow}>
          <strong>Subtotal:</strong>
          <strong>${totalAmount}</strong>
        </div>
        <div
          style={{
            ...styles.subtotalRow,
            fontWeight: 300,
            fontSize: 14,
            opacity: 0.7,
          }}
        >
          *All prices include tax
        </div>
      </div>

      <button
        onClick={handlePaymentClick}
        style={{
          ...styles.purchaseButton,
          backgroundColor: addressFilled ? "black" : "gray",
          cursor: addressFilled ? "pointer" : "not-allowed",
          display: "flex",
          justifyContent: "center",
        }}
        disabled={!addressFilled || paymentProcessing}
      >
        Purchase
        {paymentProcessing && (
          <div style={{ marginLeft: "10px" }}>
            <svg width="20" height="20" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
              <g fill="none" fillRule="evenodd">
                <g transform="translate(1 1)" strokeWidth="2">
                  <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                  <path d="M36 18c0-9.94-8.06-18-18-18">
                    <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
                  </path>
                </g>
              </g>
            </svg>
          </div>
        )}
      </button>

      <p style={styles.disclaimer}>Sooperstock is not responsible for lost or stolen packages during delivery</p>

      <p>{paymentStatus}</p>
    </div>
  );
};

const styles = {
  purchaseButton: {
    width: "100%",
    padding: "15px",
    backgroundColor: "gray",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    marginTop: "20px",
  },
  disclaimer: {
    fontSize: "12px",
    color: "gray",
    textAlign: "center",
    marginTop: "10px",
  },
  summarySection: {
    borderTop: "1px solid #ddd",
    paddingTop: "20px",
  },
  summaryRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
    fontSize: "14px",
  },
  subtotalRow: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    fontSize: "16px",
    fontWeight: "bold",
  },
  greenText: {
    color: "#1fd400",
  },
};

export default SquarePaymentForm;
