import React, { useState, useEffect, useContext } from "react";
import Header from "../../elements/Header";
import { EnvContext } from "../../context/EnvContext";
import { UserContext } from "../../context/UserContext";
// MUI copy to clipboard icon
import { IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const Help = ({ isDevMode }) => {
  //
  const environment = useContext(EnvContext);
  const { analytics } = useContext(EnvContext);
  //
  const { user } = useContext(UserContext);
  return (
    <div>
      <Header title="FAQ" />
      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <br />
          <div
            style={{
              fontSize: "26px",
              textAlign: "center",
              fontWeight: 600,
              background: "gray",
              alignSelf: "center",
              display: "flex",
              color: "white",
              borderRadius: 5,
              padding: "10px 24px",
              cursor: "pointer",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => navigator.clipboard.writeText(`http://sooperstock.com/?ui=${user?.user_code}`)}
          >
            <IconButton style={{ color: "white", marginRight: 10 }} size="small">
              <ContentCopyIcon />
            </IconButton>
            Copy link
          </div>
          <div style={{ paddingTop: 15 }}>http://sooperstock.com/?ui={user?.user_code}</div>
        </div>
      </div>
    </div>
  );
};

export default Help;
