import React, { useState, useContext, useEffect, useCallback, useRef, useMemo } from "react";
import Header from "../../elements/Header";
import SquarePaymentForm from "../../elements/SquarePaymentForm";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { useFirestore } from "../../firebase/FirestoreContext";
import { doc, updateDoc, deleteDoc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { EnvContext } from "../../context/EnvContext";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material"; // Import MUI Dialog
import { useNavigate } from "react-router-dom";
import { getAnalytics, logEvent } from "firebase/analytics";
import config from "../../config";

// Stripe
import { loadStripe } from "@stripe/stripe-js";

// get URL domain like localhost:3000 or sooperstock.com
const redirectURL = window.location.origin;

const Checkout = () => {
  //
  const environment = useContext(EnvContext);
  const { analytics } = useContext(EnvContext);
  //
  const firestore = useFirestore();
  //
  const forceStagingPayment = false;

  const isStripeStaging = environment === "staging";
  const stripePublicKey =
    isStripeStaging || forceStagingPayment
      ? "pk_test_51Q7NV6BmPhwnfvGwfu86VnL4v1Hk1ERn0nT7VgJbUpsZcrysBau6hiOdE187kwVtikRtidfIK3l3JM2JEjxriSy000QMRtluU5"
      : "pk_live_51Q7NV6BmPhwnfvGw5LHXyRuz5pnpQcOU3UEgx5WFI2UiMONqNq461OPuufnhgyqtgs8sDusOzQPCUJKVGiaBCXgg00SQHwT9Hg";

  // Load Stripe
  const stripePromise = loadStripe(stripePublicKey);
  //
  const navigate = useNavigate();
  const location = useLocation();
  const { cartID = null, cart_sooperstock_ID = null, items = [], subtotal = 0, shippingCostsubtotal = 0 } = location.state || {};

  // environment
  const collectionPath = isStripeStaging ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  const validSubtotal = subtotal || 0;
  const validShippingCost = shippingCostsubtotal || 0;

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("Stripe"); // Default is Stripe
  const [purchaseButtonDisabled, setPurchaseButtonDisabled] = useState(true);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false); // State for login prompt
  const [email_address, setEmailAddress] = useState(""); // Email input state for guests
  // dropdown
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownHeight, setDropdownHeight] = useState(0);
  const dropdownRef = useRef(null);
  const [optionsCount, setOptionsCount] = useState(0); // Track the number of options
  // email verification
  const [confirmEmailAddress, setConfirmEmailAddress] = useState(""); // Email confirmation input state for guests
  const [emailError, setEmailError] = useState(""); // State for email error

  // discount code
  const [discountCode, setDiscountCode] = useState("");
  const [appliedDiscount, setAppliedDiscount] = useState({ discount: 0 });
  const [discountCodeMessage, setDiscountCodeMessage] = useState(""); // Inline message for discount code
  const [discountMessageStyle, setDiscountMessageStyle] = useState({ color: "black" }); // Style for message

  //
  const totalAmount = useMemo(() => {
    // Ensure appliedDiscount is always an object
    const discountAmount = appliedDiscount && appliedDiscount?.discount ? (validSubtotal * appliedDiscount?.discount) / 100 : 0;
    return (validSubtotal - discountAmount + validShippingCost).toFixed(2);
  }, [validSubtotal, validShippingCost, appliedDiscount]);

  // get shipmentPrice and freeShipmentThreshold
  useEffect(() => {
    const fetchGatewayData = async () => {
      try {
        const gatewayDocRef = doc(firestore, `${collectionPath}SooperstockSettings`, "PaymentGateways");
        const gatewayDocSnapshot = await getDoc(gatewayDocRef);

        if (gatewayDocSnapshot.exists()) {
          const gatewayData = gatewayDocSnapshot.data();
          gatewayData.defaultPaymentGateway = gatewayData.defaultPaymentGateway || "Stripe";
          setSelectedPaymentMethod(gatewayData.defaultPaymentGateway);
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchGatewayData();
  }, [firestore]);

  // Dynamically track the Google Places dropdown and update the layout
  useEffect(() => {
    const interval = setInterval(() => {
      // Use role="listbox" to target the dropdown and role="option" for options
      const dropdownElement = document.querySelector('[role="listbox"]');
      if (dropdownElement) {
        // Count the number of option elements (role="option")
        const options = dropdownElement.querySelectorAll('[role="option"]');
        setOptionsCount(options.length); // Update the number of options
        const newHeight = dropdownElement.getBoundingClientRect().height;
        setDropdownHeight(newHeight);
      } else {
        setOptionsCount(0); // Reset the count if dropdown is not visible
        setDropdownHeight(0); // Reset height if dropdown is not visible
      }
    }, 100); // Check every 100ms

    return () => clearInterval(interval); // Clean up interval on unmount
  }, []);

  // analytics
  useEffect(() => {
    if (analytics) {
      logEvent(analytics, "page_view", {
        page_title: "Checkout Page",
        page_location: window.location.href,
        page_path: window.location.pathname,
      });
    }
  }, []);

  // User context
  const { user, loading } = useContext(UserContext);
  const db = useFirestore();

  // Addresses
  const [addresses, setAddresses] = useState([]);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0); // Default to the first address
  const [showAddressOptions, setShowAddressOptions] = useState(false);
  const [showAddNewForm, setShowAddNewForm] = useState(false);

  // New address form states
  const [address_name, setAddressName] = useState("");
  const [address_autocomplete, setAddressAutocomplete] = useState(null);
  const [address_optional, setAddressOptional] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      return; // Don't run the rest of the logic while loading
    }

    if (user && user.addresses && user.addresses.length > 0) {
      setAddresses(user.addresses);
      fetchCheckoutSessionId(user.email, user); // Fetch checkout session ID if user is logged in
    } else {
      setAddresses([]);
    }
  }, [user, loading]);

  useEffect(() => {
    // Only redirect if we're done loading and no user is present
    if (!loading && !user) {
      navigate("/"); // Redirect to home if no user is authenticated
    }
  }, [loading, user, navigate]);

  // Stripe
  const fetchCheckoutSessionId = useCallback(
    async (currentEmail, currentUser) => {
      console.log("FUNCTION fetchCheckoutSessionId: START", `${new Date().toLocaleTimeString()}.${new Date().getMilliseconds()}`);

      if (!items || items.length === 0) {
        console.error("No items in cart.");
        return;
      }

      // Calculate the discount on items only
      const discountPercentage = appliedDiscount ? appliedDiscount?.discount : 0;

      // Prepare line items with discounted prices if applicable
      const lineItems = items.map((item) => {
        //const originalPriceInCents = Math.round(item.finalPrice * 100);
        //const discountedPriceInCents = discountPercentage ? Math.round(originalPriceInCents * (1 - discountPercentage / 100)) : originalPriceInCents;

        const discountedPriceInCents = discountPercentage
          ? Math.floor(item.finalPrice * 100 * (1 - discountPercentage / 100))
          : Math.floor(item.finalPrice * 100);

        return {
          name: item.product_name,
          description: item.description,
          amount: discountedPriceInCents, // Price in cents after applying discount
          currency: "usd",
          quantity: item.quantity,
        };
      });

      // Add shipment as a separate line item (without discount)
      if (shippingCostsubtotal > 0) {
        lineItems.push({
          name: "Shipping",
          description: "Shipping Cost",
          amount: Math.round(shippingCostsubtotal * 100), // Shipping cost in cents
          currency: "usd",
          quantity: 1,
        });
      }

      const payload = {
        lineItems,
        client_reference_id: cartID,
        totalAmount: Math.round((subtotal - (subtotal * discountPercentage) / 100 + shippingCostsubtotal) * 100, 2),
        isStripeStaging: isStripeStaging || forceStagingPayment,
        redirectURL: redirectURL,
        customer_email: currentEmail || "",
        metadata: {
          discount_code: appliedDiscount?.code || "",
          discount_amount: ((subtotal * discountPercentage) / 100).toFixed(2),
        },
      };

      return fetch("https://us-central1-sooperstock-app.cloudfunctions.net/api/payment/stripeCreateCheckoutSession", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log("FUNCTION fetchCheckoutSessionId: END", `${new Date().toLocaleTimeString()}.${new Date().getMilliseconds()}`);
          return {
            sessionId: data.sessionId,
            paymentIntentId: data.id,
            paymentData: data,
          };
        })
        .catch((error) => {
          console.error("Error fetching sessionId:", error);
        });
    },
    [items, subtotal, shippingCostsubtotal, appliedDiscount]
  );

  //
  //
  const handleStripeCheckout = async () => {
    if (analytics) {
      logEvent(analytics, "select_content", {
        content_type: "checkout_button",
      });
      console.log("PAYOUT START", `${new Date().toLocaleTimeString()}.${new Date().getMilliseconds()}`);
    }
    if (!user || !user.uid) {
      setShowLoginPrompt(true); // Show login prompt if user is not logged in
      return;
    }

    // Enforce physical address and email if user is a guest (no email)
    if (!addresses[0]?.email && !user.email) {
      alert("Please provide both an email address and a physical shipment address.");
      return;
    }

    const updatedAddress = {
      ...addresses[selectedAddressIndex],
      email: user?.email || addresses[selectedAddressIndex]?.email,
    };
    addresses[selectedAddressIndex] = updatedAddress;

    setIsLoading(true); // Start loading
    console.log("STRIPE: Starting fetchCheckoutSessionId() function...", `${new Date().toLocaleTimeString()}.${new Date().getMilliseconds()}`);

    const { sessionId, paymentIntentId, paymentData } = await fetchCheckoutSessionId(addresses[0]?.email, user);
    console.log("STRIPE: Finish fetchCheckoutSessionId() function...", `${new Date().toLocaleTimeString()}.${new Date().getMilliseconds()}`);

    if (sessionId) {
      console.log("getted sessionId...", `${new Date().toLocaleTimeString()}.${new Date().getMilliseconds()}`);
      const stripe = await stripePromise;

      //
      // Calculate discount amount
      const discountAmount = appliedDiscount && appliedDiscount?.discount ? (subtotal * appliedDiscount.discount) / 100 : 0;

      // Store both stripe session ID and payment intent ID in cart document
      console.log("SOOPERSTOCK: Starting store Stripe Session Id", `${new Date().toLocaleTimeString()}.${new Date().getMilliseconds()}`);
      const docRef = doc(db, `${collectionPath}Carts/CartsList/DataBase`, cartID);
      updateDoc(docRef, {
        stripeSessionId: sessionId,
        stripePaymentIntentId: paymentIntentId,
        stripePaymentData: paymentData,
        shipmentAddress: addresses[selectedAddressIndex],
        isAnonymous: user.isAnonymous,
        discountCode: appliedDiscount?.code || null,
        discountApplied: appliedDiscount?.discount,
        discountAmount: discountAmount.toFixed(2),
      });

      console.log("SOOPERSTOCK: Finish store Stripe Session Id", `${new Date().toLocaleTimeString()}.${new Date().getMilliseconds()}`);

      console.log("PAYOUT END / STRIPE: trigger to redirect on the Stripe Payout", `${new Date().toLocaleTimeString()}.${new Date().getMilliseconds()}`);
      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        console.error("Stripe Checkout error:", error);
        setIsLoading(false);
      }
    } else {
      console.error("Failed to retrieve Checkout session ID.");
      setIsLoading(false);
    }
  };

  const handlePaymentSuccess = async () => {
    const docRef = doc(db, `${collectionPath}Carts/CartsList/DataBase`, cartID);
    await deleteDoc(docRef);

    console.log("Payment succeeded!");
  };

  const handlePaymentFailure = () => {
    console.log("Payment failed.");
  };

  useEffect(() => {
    validatePurchaseButton();
  }, [selectedPaymentMethod, addresses]);

  const validatePurchaseButton = () => {
    console.log("Validating button:", selectedPaymentMethod, addresses?.length > 0);
    if (selectedPaymentMethod && addresses?.length > 0) {
      setPurchaseButtonDisabled(false); // Enable buttons if conditions are met
    } else {
      setPurchaseButtonDisabled(true); // Keep buttons disabled otherwise
    }
  };

  const toggleAddressOptions = () => {
    if (addresses?.length <= 1) {
      // If there's no other address, show the add new form immediately
      setShowAddNewForm(true);
    } else {
      setShowAddressOptions(!showAddressOptions);
      setShowAddNewForm(false); // Ensure add new form is not visible
    }
  };

  const handleAddressSelect = (index) => {
    setSelectedAddressIndex(index);
    setShowAddressOptions(false);
  };

  const handleAddNewAddress = () => {
    setShowAddNewForm(true);
    setShowAddressOptions(false); // Ensure address options are hidden
  };

  const handleSaveNewAddress = async () => {
    //
    if (analytics) {
      logEvent(analytics, "add_shipping_address", {
        content_type: "Adding address",
      });
    }
    // Ensure user is defined
    if ((!user?.email && !validateEmail(email_address)) || !address_name || !address_autocomplete) {
      alert("Please provide a valid email, address, and name.");
      return;
    }

    if (!user || !user.uid) {
      console.error("User is not defined.");
      return;
    }

    const userID = user.uid;
    const userRef = doc(db, `Users/UsersList/DataBase`, userID);

    // Extract address details
    const formattedAddress = address_autocomplete ? address_autocomplete.label : "";
    const placeId = address_autocomplete ? address_autocomplete.value.place_id : "";

    const newAddressData = {
      address_name: address_name || "",
      address: formattedAddress,
      email: user?.email || email_address,
      address_optional: address_optional || "",
      place_id: placeId,
    };

    const updatedAddresses = [...addresses, newAddressData];

    try {
      // Update addresses in Firestore
      await updateDoc(userRef, { addresses: updatedAddresses });
      setAddresses(updatedAddresses);
      setSelectedAddressIndex(updatedAddresses.length - 1); // Select the new address
      setShowAddNewForm(false);
      // Reset form fields
      setAddressName("");
      setAddressAutocomplete(null);
      setAddressOptional("");
    } catch (error) {
      console.error("Error saving new address:", error);
    }
  };

  // Email validation function
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Add this check inside your form handling to ensure emails match
  useEffect(() => {
    if (email_address && confirmEmailAddress && email_address !== confirmEmailAddress) {
      setEmailError("Emails do not match");
    } else {
      setEmailError("");
    }
  }, [email_address, confirmEmailAddress]);

  //
  const isSaveButtonDisabled =
    (!user?.email && (!validateEmail(email_address) || !address_name || !address_autocomplete)) || (user?.email && (!address_name || !address_autocomplete));

  // If user is not defined, show loading

  //
  const handleApplyDiscountCode = async () => {
    const discountsCollection = collection(firestore, `${collectionPath}Marketing/DiscountsCodes/DataBase`);
    const discountQuery = query(discountsCollection, where("code", "==", discountCode.toUpperCase()));

    try {
      const querySnapshot = await getDocs(discountQuery);

      if (!querySnapshot.empty) {
        const docSnap = querySnapshot.docs[0];
        const data = docSnap.data();

        // Validate if the discount is within the valid date range
        const startDate = data.startDate.toDate();
        const endDate = data.endDate.toDate();
        const today = new Date();

        console.log("Start Date:", startDate);
        console.log("End Date:", endDate);
        console.log("Today:", today);

        // Validate if the discount is within the valid date range
        if (startDate.getTime() <= today.getTime() && today.getTime() <= endDate.getTime()) {
          setAppliedDiscount({ code: data.code, discount: data.amount });
          setDiscountCodeMessage("Discount applied!");
          setDiscountMessageStyle({ color: "green" });
        } else {
          setAppliedDiscount(null);
          setDiscountCodeMessage("Discount code expired.");
          setDiscountMessageStyle({ color: "red" });
        }
      } else {
        setAppliedDiscount(null);
        setDiscountCodeMessage("Invalid discount code.");
        setDiscountMessageStyle({ color: "red" });
      }
    } catch (error) {
      console.error("Error applying discount:", error);
      setDiscountCodeMessage("Failed to apply discount code.");
      setDiscountMessageStyle({ color: "red" });
    }
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      <Dialog open={showLoginPrompt} onClose={() => setShowLoginPrompt(false)}>
        <DialogTitle>Login Required</DialogTitle>
        <DialogContent>
          <p>You need to login or create an account to finish your order.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowLoginPrompt(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Header />
      <div style={styles.checkoutContainer}>
        <h2 style={styles.orderTitle}>Place your order</h2>

        {/* Delivery Information */}
        <div style={styles.address}>
          <h4 style={styles.headerText}>Delivery to</h4>
          {addresses?.length > 0 ? (
            <>
              <div style={styles.addressBox}>
                <h4 style={styles.nameText}>{addresses[selectedAddressIndex].address_name}</h4>
                <p style={styles.addressText}>{addresses[selectedAddressIndex].address}</p>
                <p style={styles.addressText}>{addresses[selectedAddressIndex].address_optional}</p>
              </div>
              <button style={styles.linkButton} onClick={toggleAddressOptions}>
                Use a different address
              </button>
            </>
          ) : (
            <button style={styles.linkButton} onClick={toggleAddressOptions}>
              Please add an address.
            </button>
          )}

          {/* Address Options */}
          {showAddressOptions && (
            <>
              {addresses.length > 1 && (
                <div
                  style={{
                    ...styles.addressOptions,
                    width: "100%",
                    cursor: "pointer",
                  }}
                >
                  {addresses.map((addr, index) => {
                    if (index !== selectedAddressIndex) {
                      return (
                        <div key={index} style={styles.addressBox} onClick={() => handleAddressSelect(index)}>
                          <h4 style={styles.nameText}>{addr.address_name}</h4>
                          <p style={styles.addressText}>{addr.address}</p>
                          <p style={styles.addressText}>{addr.address_optional}</p>
                          <p style={styles.changeAddressButton}>Use this address</p>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              )}
              <button style={styles.linkButton} onClick={handleAddNewAddress}>
                Add new address
              </button>
            </>
          )}

          {/* Add New Address Form */}
          {showAddNewForm && (
            <div style={{ ...styles.editForm, width: "100%", marginBottom: 20 }}>
              {!user?.email && (
                <>
                  {/* Email input */}
                  <label style={styles.label}>Please enter the recipient’s email address</label>
                  <input
                    type="text"
                    value={email_address}
                    onChange={(e) => setEmailAddress(e.target.value)}
                    style={styles.input}
                    placeholder="Email address"
                    required
                  />

                  {/* Confirm Email input with disabled copy-paste */}
                  <label style={styles.label}>Please confirm email address</label>
                  <input
                    type="text"
                    value={confirmEmailAddress}
                    onChange={(e) => setConfirmEmailAddress(e.target.value)}
                    style={styles.input}
                    placeholder="Confirm Email address"
                    required
                    onPaste={(e) => e.preventDefault()} // Disable paste
                    onCopy={(e) => e.preventDefault()} // Disable copy
                  />
                  {emailError && <p style={{ color: "red" }}>{emailError}</p>}
                </>
              )}
              <label style={styles.label}>Please enter the recipient’s name</label>
              <input
                type="text"
                value={address_name}
                onChange={(e) => setAddressName(e.target.value)}
                style={styles.input}
                placeholder="Your Name (First Last)"
              />

              <label style={styles.label}>Please enter a delivery address</label>
              <div style={{ ...styles.autocompleteContainer }}>
                <GooglePlacesAutocomplete
                  apiKey="YOUR_GOOGLE_MAPS_API_KEY"
                  selectProps={{
                    value: address_autocomplete,
                    onChange: setAddressAutocomplete,
                    placeholder: "Search for an address",
                    onMenuOpen: () => setDropdownOpen(true),
                    onMenuClose: () => setDropdownOpen(false),
                    styles: {
                      control: (provided) => ({
                        ...provided,
                        fontSize: "14px",
                        padding: "0px",
                        margin: "0px",
                        boxSizing: "border-box",
                        minHeight: "40px",
                      }),
                      input: (provided) => ({
                        ...provided,
                        fontSize: "14px",
                        padding: "0px 0px",
                        margin: "0px",
                        boxSizing: "border-box",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        padding: "0px 10px",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        fontSize: "14px",
                        margin: "0px",
                      }),
                      option: (provided) => ({
                        ...provided,
                        fontSize: "14px",
                      }),
                      dropdownIndicator: (provided) => ({
                        ...provided,
                        display: "none",
                      }),
                      // Remove the indicator separator (line next to the arrow)
                      indicatorSeparator: (provided) => ({
                        display: "none",
                      }),
                    },
                  }}
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: ["us", "be"],
                    },
                  }}
                />
                <div ref={dropdownRef} />
              </div>

              <input
                type="text"
                value={address_optional}
                onChange={(e) => setAddressOptional(e.target.value)}
                style={{
                  ...styles.input,
                  marginTop: dropdownOpen ? `${dropdownHeight}px` : "10px", // Adjust margin based on dropdown height
                }}
                placeholder="Apt or building number (optional)"
              />

              <button
                style={{
                  ...styles.saveButton,
                  ...(isSaveButtonDisabled && {
                    backgroundColor: "gray", // Red background if disabled
                    cursor: "not-allowed", // Change cursor to indicate disabled
                    opacity: 0.5, // Reduce opacity when disabled
                  }),
                }}
                onClick={handleSaveNewAddress}
                disabled={isSaveButtonDisabled}
              >
                Save address
              </button>
              <button style={styles.cancelButton} onClick={() => setShowAddNewForm(false)}>
                Cancel
              </button>
            </div>
          )}
        </div>

        {shippingCostsubtotal !== 0 && (
          <div
            style={{
              padding: "20px 0px 20px 0px",
              color: "#1fd400",
              fontWeight: "400",
              borderTop: "1px solid #ddd",
            }}
          >
            FREE shipping for orders over $100
          </div>
        )}

        {/* Payment Method */}
        <div style={{ ...styles.section }}>
          <div style={{ display: "none" }}>
            <h4 style={styles.sectionTitle}>Payment method</h4>
            <div>
              <label>
                <input type="radio" value="Stripe" checked={selectedPaymentMethod === "Stripe"} onChange={() => setSelectedPaymentMethod("Stripe")} />
                Pay with Stripe
              </label>
              <label style={{ marginLeft: "10px" }}>
                <input type="radio" value="Square" checked={selectedPaymentMethod === "Square"} onChange={() => setSelectedPaymentMethod("Square")} />
                Pay with Square
              </label>
            </div>
          </div>
          <div style={{ ...styles.summaryRow, display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
            <div>
              <span>Discount Code:</span>
            </div>
            <div style={{ ...styles.summaryRow, display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <input
                  type="text"
                  value={discountCode}
                  onChange={(e) => setDiscountCode(e.target.value)}
                  placeholder="Enter code"
                  style={{ fontSize: "14px", borderRadius: 0 }}
                />
                <div
                  onClick={handleApplyDiscountCode}
                  style={{
                    marginLeft: "10px",
                    padding: "5px 10px",
                    background: "black",
                    height: 39,
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  Apply
                </div>
              </div>
              <div>
                <p style={{ ...discountMessageStyle, fontSize: "12px", marginTop: "0px", textAlign: "left" }}>{discountCodeMessage}</p>
              </div>
            </div>
            {/* Inline message for discount code status */}
          </div>

          {/* Conditional rendering for Stripe or Square based on selected payment method */}
          {selectedPaymentMethod === "Stripe" && (
            <>
              <div style={styles.summarySection}>
                <div style={styles.summaryRow}>
                  <span>
                    Items:
                    {
                      // list of items in cart
                      items &&
                        items.length > 0 &&
                        items.map((item) => (
                          <div key={item.product_id} style={{ padding: "1px 0", opacity: 0.7 }}>
                            <span style={{ fontSize: "12px" }}>
                              - {item.product_name} x {item.quantity}
                            </span>
                          </div>
                        ))
                    }
                  </span>
                  <span>${subtotal && subtotal.toFixed(2)}</span>
                </div>

                {appliedDiscount?.code && (
                  <div style={styles.summaryRow}>
                    <span>Promotion ({appliedDiscount?.code}):</span>
                    <span style={{}}>- {appliedDiscount?.discount}%</span>
                  </div>
                )}
                <div style={styles.summaryRow}>
                  <span>Subtotal:</span>
                  <span style={{}}>${shippingCostsubtotal ? (totalAmount - shippingCostsubtotal).toFixed(2) : totalAmount}</span>
                </div>
                <div style={styles.summaryRow}>
                  <span>Shipping:</span>
                  <span style={styles.greenText}>${shippingCostsubtotal ? shippingCostsubtotal.toFixed(2) : "0.00"}</span>
                </div>
                <div style={styles.subtotalRow}>
                  <strong>Total:</strong>
                  <strong>${totalAmount}</strong>
                </div>
              </div>

              <button
                onClick={handleStripeCheckout}
                disabled={addresses.length === 0 || isLoading} // Ensure there is an address selected
                style={{
                  ...styles.stripeButton,
                  width: "100%",
                  padding: 13,
                  backgroundColor: isLoading || addresses.length === 0 ? "gray" : "black",
                  color: isLoading || addresses.length === 0 ? "rgba(255, 255, 255, 0.5)" : "white",

                  fontSize: "1rem",
                  border: 0,
                  cursor: isLoading || addresses.length === 0 ? "not-allowed" : "pointer",
                }} // New button styling
              >
                {isLoading ? (
                  <span>Loading...</span> // Show loading spinner or text when loading
                ) : (
                  "Proceed to payment"
                )}
              </button>

              {!user && (
                <div
                  style={{
                    textAlign: "center",
                    paddingTop: 20,
                    fontSize: 12,
                    color: "gray",
                  }}
                >
                  Please <a href="/signin">login</a> or <a href="/signup">register</a> to proceed with payment
                </div>
              )}
              {!addresses.length > 0 && (
                <div
                  style={{
                    textAlign: "center",
                    paddingTop: 20,
                    fontSize: 12,
                    color: "darkred",
                  }}
                >
                  Please add an address to proceed with payment
                </div>
              )}
              {isLoading && (
                <div
                  style={{
                    textAlign: "center",
                    paddingTop: 20,
                    fontSize: 12,
                    color: "gray",
                  }}
                >
                  This may take a few moments
                </div>
              )}
            </>
          )}

          {selectedPaymentMethod === "Square" && (
            <SquarePaymentForm
              orderAmount={totalAmount}
              onSuccess={handlePaymentSuccess}
              onFailure={handlePaymentFailure}
              cartID={cartID}
              cart_sooperstock_ID={cart_sooperstock_ID}
              items={items}
              subtotal={subtotal}
              totalAmount={totalAmount}
              address={addresses[selectedAddressIndex]} // Pass the selected address to the payment form
            />
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  checkoutContainer: {
    padding: "20px",
    margin: "0 auto",
    fontFamily: "'Inter', sans-serif",
  },
  orderTitle: {
    fontSize: "18px",
    marginBottom: "20px",
  },
  orderNumber: {
    fontWeight: "bold",
  },
  section: {
    marginBottom: "30px",
  },
  sectionTitle: {
    textTransform: "uppercase",
    fontSize: "12px",
    color: "gray",
    marginBottom: "10px",
  },

  recipient: {
    fontWeight: "bold",
    display: "block",
    marginBottom: "5px",
  },
  address: {
    marginBottom: "10px",
  },
  changeAddressButton: {
    color: "blue",
    background: "none",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
    textDecoration: "underline",
  },
  paymentMethodBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px",
  },
  paymentMethodInfo: {
    display: "flex",
    flexDirection: "column",
  },
  paymentMethodDescription: {
    color: "gray",
    fontSize: "12px",
  },
  radioButton: {
    marginTop: "10px",
    height: "20px",
    width: "20px",
  },
  summarySection: {
    borderTop: "1px solid #ddd",
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  summaryRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
    fontSize: "14px",
  },
  subtotalRow: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    fontSize: "16px",
    fontWeight: "bold",
  },
  greenText: {
    color: "#1fd400",
  },
  purchaseButton: {
    width: "100%",
    padding: "15px",
    backgroundColor: "gray",
    color: "white",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    marginTop: "20px",
  },
  disclaimer: {
    fontSize: "12px",
    color: "gray",
    textAlign: "center",
    marginTop: "10px",
  },
  addressOptions: {
    margin: "10px 0px",
    display: "flex",
    flexDirection: "column",
  },
  addressOption: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  addNewAddressButton: {
    color: "blue",
    background: "none",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
    textDecoration: "underline",
  },
  //
  address: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    backgroundColor: "#fff",
    alignItems: "flex-start",
  },
  headerText: {
    fontSize: "14px",
    color: "gray",
    marginBottom: "10px",
  },
  addressBox: {
    width: "100%",
    border: "1px solid #ddd",
    padding: "15px",
    textAlign: "left",
    marginBottom: "0px",
  },
  nameText: {
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  addressText: {
    fontSize: "14px",
    margin: "2px 0",
  },
  linkButton: {
    fontSize: "14px",
    color: "blue",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    textDecoration: "underline",
    marginBottom: "20px",
    marginTop: "10px",
  },
  editForm: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    fontSize: "14px",
    color: "gray",
    marginBottom: "5px",
  },
  input: {
    fontSize: "14px",
    padding: "10px",
    marginBottom: "15px",
    border: "1px solid #ddd",
  },
  autocompleteContainer: {
    marginBottom: "15px",
  },
  saveButton: {
    backgroundColor: "black",
    color: "white",
    height: "50px",
    marginTop: "10px",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    textAlign: "center",
  },
  cancelButton: {
    fontSize: "14px",
    color: "black",
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    textDecoration: "underline",
    marginTop: "15px",
  },
};

export default Checkout;
