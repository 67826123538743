// src/elements/HeaderShop.js

import React, { useEffect, useState, useContext } from "react";
import "../styles/global.css";
import { useNavigate } from "react-router-dom";
import { MenuDrawer } from "../screens/_menuDrawer";
import SearchFilters from "./SearchFilters";
import { useSearch } from "../context/SearchContext";
import { getAuth } from "firebase/auth";
import countCartItems from "../functions/countCartItems";
import { useFirestore } from "../firebase/FirestoreContext";
import { EnvContext } from "../context/EnvContext";
import { useSwipeable } from "react-swipeable";
import Tooltip from "@mui/material/Tooltip";
import { logEvent } from "firebase/analytics";
import config from "../config";
// mui x font

function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

const HeaderShop = ({ handleSearchTerm, applyFilters, clearFilters, setContentType }) => {
  // environment
  const environment = useContext(EnvContext);
  const { analytics } = useContext(EnvContext);
  //
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [localSearchTerm, setLocalSearchTerm] = useState("");
  const [isSearchEnabled, setSearchEnabled] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const [filters, setFilters] = useState({
    categories: [],
    types: [],
    priceOrder: "",
    inStockOnly: false,
  });
  const [recentSearchTerms, setRecentSearchTerms] = useState([]);
  const [openFilter, setOpenFilter] = useState(null);

  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;
  const firestore = useFirestore(); // Get Firestore instance

  const searchContext = useSearch(); // Use search context
  const { setSearchTerm, searchProducts, storeSearchTerm, getHistoryTerms, toggleRecentSearches, showRecentSearches } = searchContext || {}; // Destructure with fallback

  // environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  useEffect(() => {
    window.feather.replace();
  }, [isMenuOpen]);

  useEffect(() => {
    setSearchEnabled(localSearchTerm.length > 3);
  }, [localSearchTerm]);

  useEffect(() => {
    let unsubscribe; // Variable to hold the unsubscribe function

    if (user?.uid) {
      // Subscribe to cart item count updates if user is logged in
      unsubscribe = countCartItems(firestore, user.uid, setCartCount, collectionPath);
    } else {
      setCartCount(0); // Set count to 0 if no user is logged in
    }

    // Cleanup listener when the component is unmounted
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [firestore, user]);

  const handleApplyFilters = (newFilters) => {
    setFilters(newFilters);
    applyFilters(newFilters, localSearchTerm); // Apply filters with the search term
  };

  //
  useEffect(() => {
    const debouncedSearch = debounce(() => {
      if (localSearchTerm.length) {
        applyFilters(filters, localSearchTerm); // Apply filters on search term
        searchProducts(localSearchTerm); // Search products
        toggleRecentSearches(false); // Hide recent searches
      }
    }, 300); // Debounce to avoid too many searches

    debouncedSearch(); // Trigger debounced search

    return () => {
      clearTimeout(debouncedSearch); // Clean up the timeout
    };
  }, [localSearchTerm, filters, applyFilters, searchProducts, toggleRecentSearches]); // Include dependencies to avoid re-renders

  const toggleMenu = () => {
    //
    if (analytics) {
      logEvent(analytics, "select_content", {
        content_type: "menu",
        item_id: "menu",
      });
    }
    setMenuOpen((prev) => !prev);
  };

  const handleSearchInputChange = (e) => {
    const value = e.target.value;
    setLocalSearchTerm(value); // Update input state
    setSearchTerm(value); // Update search term in context
    handleSearchTerm(value); // Pass the search term up

    if (value.length === 0) {
      // Clear the search and reset filters without flickering
      clearFilters(); // Ensure this function resets all filters and shows all products
      setContentType("products"); // Switch back to products content
    } else {
      // Apply filters based on the search term
      setContentType("products");
    }
  };

  const handleSearchClick = async () => {
    if (isSearchEnabled) {
      await storeSearchTerm(localSearchTerm); // Store the search term
      applyFilters(filters, localSearchTerm); // Apply filters with the search term
      setContentType("searchResults"); // Switch to search results content
      searchProducts(localSearchTerm); // Perform search
      toggleRecentSearches(false); // Hide recent searches
    }
  };

  const handleFocus = async () => {
    const terms = await getHistoryTerms(); // Fetch recent searches
    setRecentSearchTerms(terms); // Store them in state
    setContentType("recentSearches"); // Set content type to "recentSearches"
    toggleRecentSearches(true); // Show recent searches
  };

  //
  const swipeHandlers = useSwipeable({
    onSwipedRight: () => setMenuOpen(true), // Open menu on right swipe
    onSwipedLeft: () => setMenuOpen(false), // Close menu on left swipe
  });

  return (
    <>
      {openFilter && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,.05)",
            zIndex: 1,
            // ignore click event
          }}
          onClick={() => setOpenFilter(null)}
        ></div>
      )}
      <header
        className="header-shop"
        style={{
          ...styles.headerShop,
          ...(environment == "staging" ? { background: "#dae121" } : {}),
        }}
      >
        <div style={{ ...styles.header.container }}>
          <div style={styles.menu.container} onClick={toggleMenu}>
            <i data-feather={isMenuOpen ? "x" : "menu"} style={{ width: "16px", height: "16px" }} />
          </div>
          <div style={styles.logo.container} onClick={() => navigate("/")}>
            <img src="/images/sooperstock_logo.svg" alt="Sooperstock" style={{ height: "35px" }} />
          </div>
          <Tooltip
            title={user && user.uid ? "" : "Start shopping or create an account to add items to your cart"}
            placement="left-start"
            disableHoverListener={!!(user && user.uid)}
          >
            <div
              style={{
                ...styles.cart.container,
                opacity: user && user.uid ? 1 : 0.5,
                cursor: user && user.uid ? "pointer" : "none",
              }}
              onClick={
                user && user.uid
                  ? () => {
                      // Track the cart click event
                      if (analytics) {
                        logEvent(analytics, "select_content", {
                          content_type: "cart",
                          item_id: "cart_page",
                        });
                      }

                      // Navigate to the cart page
                      navigate("/cart");
                    }
                  : null
              }
            >
              <div style={{ width: "16px", height: "16px", position: "relative" }}>
                <i data-feather={"shopping-bag"} style={{ width: "16px", height: "16px" }} />
                <div style={styles.cartCount}>{user && user.uid ? cartCount : 0}</div> {/* Display current number of items in cart */}
              </div>
            </div>
          </Tooltip>
        </div>
        {/** SEARCH AREA */}
        <div style={{ ...styles.search.container }}>
          <div style={styles.search.inputFieldContainer}>
            <div style={{ flex: 1, display: "flex", flexDirection: "row" }}>
              <input
                type="text"
                placeholder="Search Products"
                style={{ ...styles.search.inputField }}
                value={localSearchTerm}
                onChange={handleSearchInputChange}
                onFocus={user?.uid && handleFocus}
                onBlur={() => toggleRecentSearches(false)} // Hide recent searches on blur
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearchClick(); // Call the search function when Enter is pressed
                  }
                }}
              />

              {isSearchEnabled && (
                <div
                  style={{
                    flex: 1,
                    padding: "0px 20px",
                    color: "black",
                    alignItems: "center",
                    display: "flex",
                    zIndex: 1000,
                    fontWeight: "300",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    clearFilters(); // Clear filters and reset to full products list
                    setLocalSearchTerm(" "); // Reset the search term in the input field
                    setSearchTerm(" "); // Clear the search term in the context
                    setContentType("products"); // Ensure the content is switched to showing all products
                    handleSearchTerm(""); // Pass the search term up
                  }}
                >
                  &#10005;
                </div>
              )}
            </div>
            <div style={styles.searchButtonContainer}>
              <button
                style={{
                  ...(isSearchEnabled ? styles.searchButton.enabled : styles.searchButton.disabled),
                  ...styles.searchButton.button,
                }}
                disabled={!isSearchEnabled}
              >
                <div onClick={handleSearchClick} disabled={!isSearchEnabled} style={{ padding: "20px 10px" }}>
                  SEARCH
                </div>
              </button>
              <button onClick={clearFilters}>Clear Filters</button>
            </div>
          </div>
        </div>
        <SearchFilters onApplyFilters={handleApplyFilters} openFilter={openFilter} setOpenFilter={setOpenFilter} />
      </header>

      <MenuDrawer isOpen={isMenuOpen} toggleMenu={toggleMenu} />
    </>
  );
};

// Styles for HeaderShop component (unchanged)
const styles = {
  headerShop: {},
  header: {
    container: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px 0",
      borderBottom: ".2px solid #808080",
    },
  },
  menu: {
    container: {
      padding: "0 20px",
      cursor: "pointer",
    },
  },
  cart: {
    container: {
      padding: "0 20px",
      cursor: "pointer",
    },
  },
  cartCount: {
    position: "absolute",
    top: "-9px",
    right: "-13px",
    backgroundColor: "black",
    borderRadius: "50%",
    color: "white",
    fontSize: "10px",
    height: "18px",
    width: "18px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    fontWeight: 600,
  },
  logo: {
    container: {
      cursor: "pointer",
    },
    text: {
      fontSize: "18px",
    },
  },
  search: {
    container: {
      flexDirection: "row",
      display: "flex",
    },
    inputFieldContainer: {
      display: "flex",
      width: "100%",
      height: "45px",
      flex: 1,
    },
    inputField: {
      width: "100%",
      border: "none",
      height: "100%",
      outline: "none",
      fontSize: "14px",
    },
  },
  searchButtonContainer: {},
  searchButton: {
    button: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    enabled: {
      backgroundColor: "black",
      color: "white",
      border: "none",
      height: "100%",
      cursor: "pointer",
      width: "100%",
      padding: "0 10px 0 15px",
    },
    disabled: {
      backgroundColor: "gray",
      color: "white",
      border: "none",
      height: "100%",
      cursor: "not-allowed",
      width: "100%",
      padding: "0 10px 0 15px",
    },
  },
};

export default HeaderShop;
