import React, { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import { getFirestore, addDoc, collection } from "firebase/firestore";
import Button from "@mui/material/Button";
import { EnvContext } from "../../context/EnvContext";
import config from "../../config";

const NewsletterForm = () => {
  //
  const environment = useContext(EnvContext);
  const { analytics } = useContext(EnvContext);
  //
  const [showNewsletterModal, setShowNewsletterModal] = useState(false);
  const [newsletterAccepted, setNewsletterAccepted] = useState(false);
  const [emailInput, setEmailInput] = useState("");

  // environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  useEffect(() => {
    const newsletterResponded = Cookies.get("newsletterResponded");
    if (!newsletterResponded) {
      setTimeout(() => {
        setShowNewsletterModal(true);
      }, 60000);
    }
  }, []);

  const handleAcceptNewsletter = async () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(emailInput) || emailInput.length < 5 || !emailInput.includes("@") || !emailInput) {
      alert("Please enter a valid email address");
      return;
    }

    const firestore = getFirestore();

    try {
      await addDoc(collection(firestore, `${collectionPath}Newsletter/Subscribers/DataBase`), {
        email: emailInput,
        subscribedAt: new Date(),
      });
      Cookies.set("newsletterResponded", true, { expires: 365 });
      setShowNewsletterModal(false);
      alert("You're signed up!");
    } catch (error) {
      console.error("Error signing up for newsletter:", error);
      alert("Failed to sign up for the newsletter.");
    }
  };

  const handleDeclineNewsletter = () => {
    Cookies.set("newsletterResponded", true, { expires: 1 });
    setShowNewsletterModal(false);
  };

  if (!showNewsletterModal || newsletterAccepted) return null;

  return (
    <div style={{ ...styles.modal, flexDirection: "column", padding: "20px" }}>
      <div
        style={{
          ...styles.modalContent,
          width: "100%",
          padding: "5px",
          justifyContent: "flex-end",
          display: "flex",
        }}
      >
        <div
          onClick={handleDeclineNewsletter}
          style={{
            display: "flex",
            padding: "10px 20px",
            fontSize: "1.7rem",
            cursor: "pointer",
          }}
        >
          &times;
        </div>
      </div>
      <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/images/sooperstock_logo.svg" alt="Sooperstock" style={{ height: "55px" }} />
        </div>
        <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "flex-start",
            textAlign: "center",
          }}
        >
          <div>
            <h2 style={{ paddingBottom: "10px" }}>Sign up for our newsletter</h2>
            <p>GET FIRST LOOK AT EXCLUSIVE DEALS AND NEW PRODUCT DROPS!</p>
            <input
              type="email"
              placeholder="Enter your email"
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
              style={{ ...styles.modalInput, width: "100%" }}
            />
            <div style={styles.modalButtons}>
              <Button onClick={handleAcceptNewsletter} style={{ ...styles.modalButton, width: "100%" }}>
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  modal: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    backgroundColor: "white",
    zIndex: 1001,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContent: {
    textAlign: "center",
  },
  modalButtons: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-around",
  },
  modalButton: {
    backgroundColor: "#000",
    color: "#fff",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  modalInput: {
    padding: "10px",
    width: "80%",
    marginTop: "20px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
};

export default NewsletterForm;
